var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getPublicKeys,"item-key":"fingerprint","sort-by":['started_at'],"sort-desc":[true],"items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberPublicKeys,"options":_vm.pagination,"disable-sort":true,"data-test":"publickeyList-dataTable"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.fingerprint",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fingerprint)+" ")]}},{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hostname)+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"ddd, MMM Do YY, h:mm:ss a"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:'menu'+_vm.getPublicKeys.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list-item',{on:{"click":function($event){_vm.showPublicKeyFormDialog(_vm.getPublicKeys.indexOf(item))}}},[_c('PublicKeyFormDialog',{attrs:{"key-object":item,"create-key":false,"show":_vm.publicKeyFormDialogShow[_vm.getPublicKeys.indexOf(item)]},on:{"update:show":function($event){_vm.$set(_vm.publicKeyFormDialogShow, _vm.getPublicKeys.indexOf(item), $event)},"update":_vm.refresh}})],1),_c('v-list-item',{on:{"click":function($event){_vm.showPublicKeyDelete(_vm.getPublicKeys.indexOf(item))}}},[_c('PublicKeyDelete',{attrs:{"fingerprint":item.fingerprint,"show":_vm.publicKeyDeleteShow[_vm.getPublicKeys.indexOf(item)]},on:{"update:show":function($event){_vm.$set(_vm.publicKeyDeleteShow, _vm.getPublicKeys.indexOf(item), $event)},"update":_vm.refresh}})],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }